<template>
  <el-dialog 
    title="选择校招站点" 
    :visible.sync="dialogData.isShow" 
    :close-on-click-modal="false" 
    width="700px"
    custom-class="dialog-campus-sites">
    <el-radio-group v-model="schoolInterviewSiteId" class="radio-group-schools">
      <el-radio v-for="(item, key) in schoolSiteList" :key="key" :label="item.id">{{ item.name }}</el-radio>
    </el-radio-group>
    <template slot="footer">
      <el-button type="priamry" @click="onSave">确认</el-button>
      <el-button type="default" @click="dialogData.isShow = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { Dialog, RadioGroup, Radio, Button, Message } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Button.name]: Button
    },
    data() {
      return {
        schoolSiteList: [],
        schoolInterviewSiteId: ''
      }
    },
    created() {
      this.schoolSiteList = this.dialogData.schools;
    },
    methods: {
      onSave() {
        if(!this.schoolInterviewSiteId) {
          Message.error('请先选择校招站点');
          return;
        }
        this.$emit('success', this.schoolInterviewSiteId);
        this.dialogData.isShow = false;
      }
    }
  }
</script>
