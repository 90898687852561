<template>
  <div class="container main-page">
    <sub-head-navs></sub-head-navs>
    <div class="job-list-wrap">
      <div class="query-job-box">
        <div class="query-job-inner">
          <p class="item-query-job title">职位筛选</p>
          <div class="item-query-job">
            <el-input placeholder="搜索职位关键字" v-model="searchParams.keyWord" @keyup.enter.native="queryData()" class="input-search">
              <el-button slot="append" icon="el-icon-search" @click="queryData()">搜索职位</el-button>
            </el-input>
          </div>
          <ul class="item-query-job item-select clearfix">
            <li v-if="showSearchList.includes(3)">
              <el-select v-model="searchParams.categoryId" placeholder="职位类别" clearable @change="queryData()">
                <template v-for="(item, key) in categoryList">
                  <el-option :key="key" :label="item | FUNCTIONALIAS" :value="item"></el-option>
                </template>
              </el-select>
            </li>
            <li v-if="showSearchList.includes(2)">
              <sub-select-org v-model="searchParams.orgId" :orgList="orgList" @change="queryData()" placeholder="所属部门"></sub-select-org>
            </li>
            <li v-if="showSearchList.includes(8)">
              <el-select v-model="searchParams.companyWorkSpaceId" placeholder="工作地点" clearable @change="queryData()">
                <el-option class="job-list-query-address-option el-sl" v-for="(item, key) in cityList" :key="key" :label="item | FULLADDESS" :value="item.id"></el-option>
              </el-select>
            </li>
            <li v-if="showSearchList.includes(11)">
              <el-select v-model="searchParams.jobRankId" placeholder="职位级别" clearable @change="queryData()">
                <template v-for="(item, key) in jobLevel">
                  <el-option :key="key" :label="item.rankName" :value="item.id"></el-option>
                </template>
              </el-select>
            </li> 
            <li v-if="showSearchList.includes(10)">
              <el-select v-model="searchParams.jobClassifyId" placeholder="职能分类" clearable @change="queryData()">
                <template v-for="(item, key) in jobClassifyList">
                  <el-option :key="key" :label="item.name" :value="item.id"></el-option>
                </template>
              </el-select>
            </li>
            <li v-if="showSearchList.includes(7)">
              <el-select v-model="searchParams.eduRequire" placeholder="学历要求" clearable @change="queryData()">
                <el-option v-for="(item, key) in educations" :key="key" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </li>
          </ul>
          <sub-create-share></sub-create-share>
        </div>
      </div>
      <div class="job-list-box" v-loading="isLoading">
        <div class="item-job-list-box">
          <div v-for="(item, key) in jobList" :key="key" class="item-job-list">
            <a @click="isLogin(item.id)" href="javascript:;" class="href-item-job">
              <dl class="item-job">
                <dt v-if="item.jobName">
                  <i v-if="item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ item.jobName }}
                  <span v-if="item.minSalary && item.maxSalary && showSearchList.includes(5)" class="salary">
                    <template v-if="item.salaryType === 1">{{ item.minSalary }}K-{{ item.maxSalary }}K・{{ item.monthSalaryType }}<span v-if="item.monthSalaryType>12">薪</span></template>
                    <template v-else>{{ item.minSalary }}W-{{ item.maxSalary }}W</template>
                  </span>
                </dt>
                <dd>
                  <ul v-if="item.department || item.categoryId || item.jobClassifyName" class="job-divide-info clearfix">
                    <li v-if="item.categoryId && showSearchList.includes(3)" class="functions">{{ item.categoryId | FUNCTION }}</li>
                    <li v-if="item.department && showSearchList.includes(2)" class="org-name">{{ item.department }}</li>
                    <li v-if="item.jobRankNames && showSearchList.includes(11)" class="functions">{{ item.jobRankNames.join(',') }}</li>
                    <li v-if="item.jobClassifyName && showSearchList.includes(10)">{{ item.jobClassifyName }}</li>
                  </ul>
                  <ul v-if="(item.eduRequire || item.city) && showSearchList.some((ele) => [4, 7, 8].includes(ele))" class="job-divide-info clearfix">
                    <!-- <li v-if="item.workYear && showSearchList.includes(4)">{{ item.workYear | JOBWORKYEARS }}</li> -->
                    <li v-if="item.eduRequire && showSearchList.includes(7)">{{ item.eduRequire | JOBDEGREE }}</li>
                    <li v-if="item.city && showSearchList.includes(8)">{{ item | FULLADDESS }}</li>
                  </ul>
                  <ul v-if="(item.demand || item.modifyTime) && showSearchList.some((ele) => [1, 6].includes(ele))" class="job-between-info">
                    <li v-if="item.demand && showSearchList.includes(6)">招聘人数：{{ item.demand }}人</li>
                    <li v-if="item.modifyTime  && showSearchList.includes(1)" class="create-date">发布时间：{{ item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                  </ul>
                </dd>
              </dl>
            </a>
          </div>
        </div>
        <div class="page-box">
          <el-pagination
            layout="prev, pager, next"
            :current-page="searchParams.current"
            :page-size="searchParams.pageSize"
            :total="totalRecords"
            :hide-on-single-page="true"
            @current-change="onChangePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <popup-login-process :loginObj="loginObj"></popup-login-process>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  // PC引入使用的组件
  import { Input, Button, Select, Option, Pagination } from 'element-ui'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/pc/headNavs'
  import subSelectOrg from '@/components/pc/selectOrg'
  import subCreateShare from '@/components/pc/createShare'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'

  export default {
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      [Pagination.name]: Pagination,
      subHeadNavs,
      subSelectOrg,
      subCreateShare,
      popupLoginProcess
    },
    data() {
      return {
        isLoading: false,
        cityList: [],
        categoryList: [],
        orgList: [],
        jobClassifyList: [],
        jobLevel: [],
        searchParams: {
          websiteId: '',
          keyWord: '',
          cityCode: '',
          companyWorkSpaceId: '',
          jobClassifyId: undefined,
          jobRankId: undefined,
          categoryId: '',
          eduRequire: '',
          orgId: '',
          current: 1,
          pageSize: 20
        },
        showSearchList: [],
        totalRecords: 0,
        jobList: [],
        loginObj: {
          isShow: false
        },
        authInfoObj: {}
      }
    },
    computed: {
      ...mapGetters(['educations']),
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
    },
    created() {
      this.searchParams.websiteId = this.$route.query.websiteId || '';
      this.searchParams.keyWord = this.$route.query.keyWord || '';
      this.searchParams.categoryId = +this.$route.params.categoryId || '';
      this.searchParams.jobClassifyId = +this.$route.params.jobClassifyId || undefined;
      this.searchParams.jobRankId = +this.$route.params.jobRankId || undefined;
      this.searchParams.cityCode = +this.$route.params.cityCode || undefined;
      this.searchParams.orgId = +this.$route.params.orgId || '';
      this.searchParams.companyWorkSpaceId = +this.$route.params.companyWorkSpaceId || undefined;
      this.searchParams.moduleId = +this.$route.params.moduleId || undefined;
      this.init();
      this.queryData();
    },
    methods: {
      async init() {
        if(this.isRecommendWebsite) {
          if(this.$route.query.personId) {
            this.queryUserInfo(this.$route.query.personId);
          }
          await this.queryAuthConfig();
        }
        await this.queryConfigField();
        await this.querySearchCodes();
      },
      queryConfigField() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/config.json', {params: {
            websiteId: this.searchParams.websiteId
          }}).then(data => {
            this.showSearchList = data || []; 
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },
      querySearchCodes() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/code.json', {params: {
            websiteId: this.searchParams.websiteId
          }}).then(data => {
            if(data) {
              this.orgList = data.orgList || [];
              this.jobLevel = data.jobRankList || [];
              this.jobClassifyList = data.jobclassifyList || [];
              this.cityList = data.workSpaceList || []; 
              this.categoryList = data.categoryCode || []; 
            }
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },
      queryData(current = 1) {
        this.searchParams.current = current;
        this.isLoading = true;
        let queryParams = this.$utils.deleteKeys(Object.assign({}, this.searchParams, {
          websiteId: this.searchParams.websiteId || ''
        }));
        this.$http.get('/api/website/job/list.json', {params: {
          ...queryParams
        }}).then(data => {
          this.jobList = data.list || []; 
          this.totalRecords = data.totalRecords || 0;
        }).finally(() => {
          this.isLoading = false;
        });
      },
      queryUserInfo(personId) {
        this.$http.get('/api/internal/recom/person/info.json', {params: { personId }}).then(data => {
          if(data && data.authStatus) {
            local.set('oldPersonId', personId);
          } else {
            local.remove('oldPersonId');
          }
        });
      },
      onChangePage(index) {
        this.queryData(index);
      },
       // 获取配置信息
      queryAuthConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.searchParams.websiteId
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      isLogin(id) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(this.authInfoObj.triggerType === 2 && !this.mobile && !this.email) {
          this.loginObj.isShow = true;
        } else {
          let _win = this.$router.resolve({
              name: 'jobDetail',
              query: { websiteId: this.searchParams.websiteId },
              params: { id }
            });
          window.open(_win.href, '_blank');
        }
      },
    }
  }
</script>