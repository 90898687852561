<template>
  <sub-h5-job-detail v-if="$utils.isPhone()"></sub-h5-job-detail>
  <sub-pc-job-detail v-else></sub-pc-job-detail>
</template>

<script>
  import subH5JobDetail from '@/views/submodule/h5/jobDetail'
  import subPcJobDetail from '@/views/submodule/pc/jobDetail'

  export default {
    components: {
      subH5JobDetail,
      subPcJobDetail
    }
  }
</script>