<template>
  <div></div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      getFieldVal() {
        return (fieldId, fieldMap) => {
          fieldMap = fieldMap ? fieldMap : this.fieldMap;
          if(fieldMap && fieldMap[fieldId] && fieldMap[fieldId].fieldType === 3) { 
            if(fieldMap[fieldId].value === '1' || fieldMap[fieldId].value === 1)  return '是';
            if(fieldMap[fieldId].value === '0' || fieldMap[fieldId].value === 0)  return '否';
            return false;
          }
          if(fieldMap && fieldMap[fieldId] && fieldMap[fieldId].value) {
            const fieldData = fieldMap[fieldId];
            if([4, 5, 6, 7, 8, 9, 10].includes(fieldData.fieldType)) {
              let value = JSON.parse(fieldData.value);
              if(fieldData.fieldType === 4 && value.name) {
                return value.other ? `${value.name}——${value.other}` : value.name;
              }
              if(fieldData.fieldType === 5 && value.length) {
                let names = [];
                value.forEach(item => {
                  names.push(`${item.name}${item.other ? ('——'+item.other) : ''}`);
                });
                return names.join('，');
              }
              if(fieldData.fieldType === 6) {
                if(value.length && value[0] && value[0].id) {
                  return {
                    id: value[0].id,
                    name: value[0].fileName,
                    filePath: value[0].filePath
                  }
                }
              }
              if(fieldData.fieldType === 7 && value.timeValue) {
                return this.formatTime(value);
              }
              if(fieldData.fieldType === 8 && value.cityCode) {
                return {
                  city: value.district ? value.district : value.cityCode,
                  address: value.address
                }
              }
              if(fieldData.fieldType === 9) {
                const formatObj = {
                  1: 'YYYY',
                  2: 'YYYY-MM',
                  3: 'YYYY-MM-DD'
                };
                return `${moment(value.startTime).format(formatObj[value.timeType])} ${value.endTime ? '~' : ''} ${value.checkedNow ? '至今' : (value.endTime ? moment(value.endTime).format(formatObj[value.timeType] ): '')}`
              }
              if(fieldData.fieldType === 10 && value.name) {
                return value.name;
              }
            } else {
              return fieldData.value;
            }
          }
          return false;
        }
      }
    },
    methods: {
      formatTime(data) {
        const formatObj = {
          1: 'YYYY',
          2: 'YYYY-MM',
          3: 'YYYY-MM-DD'
        };
        if(data.timeValue) {
          return moment(data.timeValue).format(formatObj[data.timeType])        
        } else {
          return '';
        }
      }
    }
  }
</script>