<template>
  <div class="module-item">
    <el-tooltip effect="dark" :content="field.fieldDto.name" placement="top-start">
      <div class="label"><p>{{ field.fieldDto.name }}</p>：</div>
    </el-tooltip>
    <div class="content" v-if="field.fieldDto.type === 6">
      <sub-img v-if="answerImg" :cross="isPdf" :imgSrc="answerImg" />
      <a v-else :href="`/api/file/download.json?id=${ getFieldVal(field.fieldId).id}`">{{ getFieldVal(field.fieldId).name }}</a>
      <sub-img v-if="questionImg" :cross="isPdf" :imgSrc="questionImg" />
    </div>
    <div class="content" v-else-if="field.fieldDto.type === 8">{{ getFieldVal(field.fieldId).city | FULLCITY('pcd', '-', false) }}{{ getFieldVal(field.fieldId).address }}<sub-img v-if="questionImg" :cross="isPdf" :imgSrc="questionImg" /></div>
    <div class="content" v-else>{{ getFieldVal(field.fieldId) }}<sub-img v-if="questionImg" :cross="isPdf" :imgSrc="questionImg" /></div>
  </div>
</template>

<script>
  import jobDetailMix from '../../mixin'
  import subImg from '@/components/pc/dialog/bigImg'
  import { Tooltip } from 'element-ui'

  export default {
    mixins: [jobDetailMix],
    components: {
      [Tooltip.name]: Tooltip,
      subImg
    },
    props: {
      field: Object,
      isPdf: {
        type: Boolean,
        default: false
      },
      fieldMap: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      questionImg() {
        return this.field && this.field.fieldDto && this.field.fieldDto.fileUrls ? this.field.fieldDto.fileUrls[0] || '' : '';
      },
      answerImg() {
        let name = this.getFieldVal(this.field.fieldId).name;
        if(name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.png') || name.endsWith('.gif')) {
          return this.getFieldVal(this.field.fieldId).filePath;
        } else {
          return '';
        }
      }
    }
  }
</script>