<template>
  <div class="container main-page">
    <sub-head-navs v-if="websiteId>0"></sub-head-navs>
    <div class="job-info-wrap" v-loading="isLoading">
      <div class="job-base-box">
        <div class="job-base">
          <dl class="job-base-info">
            <dt>
              <i v-if="jobInfo.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" /><template v-if="jobInfo.jobName && isShowField('name')">{{ jobInfo.jobName }}</template>
              <span v-if="jobInfo.minSalary && jobInfo.maxSalary && isShowField('jobSalary')" class="salary">
                <template v-if="jobInfo.salaryType === 1">{{ jobInfo.minSalary }}K-{{ jobInfo.maxSalary }}K・{{ jobInfo.monthSalaryType }}<span v-if="jobInfo.monthSalaryType>12">薪</span></template>
                <template v-else>{{ jobInfo.minSalary }}W-{{ jobInfo.maxSalary }}W</template>
              </span>
            </dt>
            <dd>
              <ul v-if="jobInfo.city && isShowField('address') || (jobInfo.workYear || jobInfo.workYear == 0) && isShowField('workYear') || jobInfo.eduRequire && isShowField('eduRequire') || jobInfo.nature && isShowField('nature')" class="job-divide-info clearfix">
                <li v-if="jobInfo.city && isShowField('address')">{{ jobInfo.city | FULLCITY('pcd', '·') }}</li>
                <li v-if="(jobInfo.workYear || jobInfo.workYear == 0) && isShowField('workYear')">{{ jobInfo.workYear | JOBWORKYEARS }}</li>
                <li v-if="jobInfo.eduRequire && isShowField('eduRequire')">{{ jobInfo.eduRequire | JOBDEGREE }}</li>
                <li v-if="jobInfo.nature && isShowField('nature')">{{ JobNature[jobInfo.nature] }}</li>
              </ul>
              <div v-if="jobInfo.modifyTime" class="job-between-info">发布时间：{{ jobInfo.modifyTime | DATE('YYYY-MM-DD') }}</div>
            </dd>
          </dl>
          <div v-if="deliveryObj && Object.keys(deliveryObj).length" class="delivery-box">
            <span class="delivery-status el-sl">状态：{{ deliveryObj.resultName }}</span>
            <div v-if="deliveryObj.withdrawFlag || deliveryObj.editFlag" class="btns-href-box">
              <a v-if="deliveryObj.withdrawFlag" @click="onRevokeDeliver" href="javascript:;" class="btn-edit el-button"><i class="h5pp h5pp-icon-reload" />撤销投递</a>
              <router-link v-if="deliveryObj.editFlag" :to="{
                name: 'myResume',
                query: {
                  jobId: jobInfo.id,
                  websiteId: websiteId,
                  jobCompanyId: jobInfo.companyId,
                  accountId: accountId,
                  optRecordId: optRecordId,
                  schoolAmbassadorCode: deliveryObj.schoolAmbassadorCode,
                  schoolAmbassadorName: deliveryObj.schoolAmbassadorName
                }
              }" class="btn-link"><i class="h5pp h5pp-icon-edit2" />修改信息</router-link>
            </div>
            <span v-else class="deliveried">已投递</span>
          </div>
          <a v-else @click="onDeliver" href="javascript:;" class="btn-href" :class="{'no-share': websiteId<0}">投递简历</a>
          <sub-create-share v-if="websiteId>0" :jobId="jobId" />
        </div>
      </div>
      <div class="job-more-box">
        <div class="job-content">
          <div v-if="Object.keys(rewardList).length" class="job-module">
            <div class="module-title">内推奖励规则</div>
            <div v-if="rewardList.basicRuleExplain" class="module-item"><div class="label"><p>基础奖励</p>：</div><div class="content" v-html="$n2br(rewardList.basicRuleExplain)"></div></div>
            <div v-if="rewardList.specialRuleExplain" class="module-item"><div class="label"><p>特殊奖励</p>：</div><div class="content" v-html="$n2br(rewardList.specialRuleExplain)"></div></div>
          </div>
          <div class="job-module">
            <div class="module-title">基本信息</div>
            <template v-for="(field, fieldKey) in filedsConfig">
              <div v-if="isFiledCode('department', field) && jobInfo.department" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.department }}</div></div>
              <div v-else-if="isFiledCode('categoryId', field) && jobInfo.categoryId" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.categoryName }}</div></div>
              <div v-else-if="isFiledCode('director', field) && jobInfo.director" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.director }}</div></div>
              <div v-else-if="isFiledCode('demand', field) && jobInfo.demand" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.demand }}人</div></div>
              <div v-else-if="isFiledCode('underlingNumber', field) && jobInfo.underlingNumber >= 0" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.underlingNumber }}</div></div>
              <div v-else-if="isFiledCode('address', field) && (jobInfo.city || jobInfo.address)" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.city + '' | FULLCITY('p-c-d') }}{{ jobInfo.address }}</div></div>
              <div v-else-if="isFiledCode('describe', field) && jobInfo.describe" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content" v-html="$options.filters.NEWLINE(jobInfo.describe)"></div></div>
              <div v-else-if="isFiledCode('benefits', field) && jobInfo.positionBenefits && jobInfo.positionBenefits.length" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div>
                <div class="content">
                  <div v-for="(obj, index) in jobInfo.positionBenefits" :key="index" class="tag-item">{{ obj }}</div>
                </div>
              </div>
              <div v-else-if="isFiledCode('jobClassify', field) && jobInfo.jobClassifyName" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.jobClassifyName }}</div></div>
              <div v-else-if="isFiledCode('jobRank', field) && jobInfo.jobRankNames && jobInfo.jobRankNames.length" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.jobRankNames.join(' ') }}</div></div>
              <div v-else-if="isFiledCode('companyOrgId', field) && jobInfo.companyOrgName" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.companyOrgName }}</div></div>
              <div v-else-if="isFiledCode('tagIds', field) && jobInfo.tagName && jobInfo.tagName.length" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div>
                <div class="content">
                  <div v-for="(obj, index) in jobInfo.tagName" :key="index" class="tag-item">{{ obj }}</div>
                </div>
              </div>
              <sub-custom-field v-if="field.isCheck && getFieldVal(field.fieldId, jobInfo.fieldMap)" :fieldMap="jobInfo.fieldMap" :field="field" :key="fieldKey"/>
            </template>
          </div>
          <div v-if="(isShowField('age') && jobInfo.minAge && jobInfo.maxAge) || (isShowField('languageRequire') && jobInfo.languageRequire) || (isShowField('gender') && [-1, 0, 1].includes(+jobInfo.gender)) || (isShowField('businessTravel') && jobInfo.businessTravel)" class="job-module">
            <div class="module-title">岗位要求</div>
            <template v-for="(field, fieldKey) in filedsConfig">
              <div v-if="isFiledCode('age', field) && jobInfo.minAge && jobInfo.maxAge" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.minAge }}-{{ jobInfo.maxAge }}岁</div></div>
              <div v-else-if="isFiledCode('languageRequire', field) && jobInfo.languageRequire" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.languageRequire }}</div></div>
              <div v-else-if="isFiledCode('gender', field) && [-1, 0, 1].includes(+jobInfo.gender)" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ Gender[jobInfo.gender] }}</div></div>
              <div v-else-if="isFiledCode('businessTravel', field) && jobInfo.businessTravel" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.businessTravel }}</div></div>
            </template>
          </div>
          <div v-if="jobInfo.introduce" class="job-module">
            <div class="module-title">公司信息</div>
            <div class="module-item">
              <div class="content no-max" v-html="$options.filters.NEWLINE(jobInfo.introduce)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-login-process :loginObj="loginObj" />
    <popup-campus-sites v-if="campusSitesObj.isShow" :dialogData="campusSitesObj" @success="toApplyPage" />
  </div>
</template>

<script>
  import './index.scss'
  // PC引入使用的组件
  import { Button, MessageBox, Message } from 'element-ui'
  import { local } from '@/utils'
  import jobDetailMix from './mixin'
  import { JobNature, Gender } from '@/enums/EnumJob'
  import subHeadNavs from '@/components/pc/headNavs'
  import subCreateShare from '@/components/pc/createShare'
  import subCustomField from './submodule/customField'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'
  import popupCampusSites from '@/components/pc/dialog/campusSites'

  export default {
    mixins: [jobDetailMix],
    components: {
      [Button.name]: Button,
      [MessageBox.name]: MessageBox,
      subHeadNavs,
      subCreateShare,
      subCustomField,
      popupLoginProcess,
      popupCampusSites
    },
    data() {
      return {
        JobNature,
        Gender,
        isLoading: false,
        jobId: '',
        websiteId: '',
        accountId: '',
        optRecordId: '',
        recommendId: '',
        authStatus: 0,
        jobInfo: {},
        deliveryObj: {},
        authInfoObj: {},
        rewardList: {},
        schoolList: [],
        schoolInterviewSiteId: '',
        loginObj: { isShow: false },
        campusSitesObj: { isShow: false },
        hideList: [],
        filedsConfig: []
      }
    },
    computed: {
      user() {
        return JSON.parse(localStorage.getItem('user')|| '{}');
      },
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user')|| '{}')).personId || local.get('oldPersonId') || '';
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      // 是否是内推官网
      isRecommendWebsite() {
        // this.$route.query.websiteId = -1 为激活候选人页面
        return (this.$route.query.websiteId > 0 && (JSON.parse(local.get('uccnInfo') || '{}')).type) || '';
      }
    },
    created() {
      this.jobId = this.$route.params.id || '';
      this.websiteId = this.$route.query.websiteId || '';
      this.accountId = this.$route.query.accountId || '';
      this.optRecordId = this.$route.query.optRecordId || '';
      this.recommendId = this.$route.query.recommendId || '';
      this.init();
    },
    mounted() {
      if(this.isRecommendWebsite) {
        this.$utils.sendStat('0064', {
          webSiteId: this.websiteId,
          personId: this.personId,
          jobId: this.jobId
        });
      }
    },
    methods: {
      async init() {
        this.isLoading = true;
        // 登录之后调用状态
        if(this.mobile || this.email) {
          this.queryDeliveryStatus();
        }
        if(this.isRecommendWebsite) {
          let result = await this.queryAuthConfig();
          if(result) {
            Promise.all([
              this.queryConfig(),
              this.queryData(),
              this.queryReward(),
              this.isRecommendJob()
            ]).then(res => {
              this.isLoading = false;
            });
          }
        } else {
          Promise.all([
            this.queryConfig(),
            this.queryData()
          ]).then(res => {
            this.isLoading = false;
          });
        }
        if(this.optRecordId) {
          // 标记邮箱中已打开查看职位的链接
          this.openLink();
        }
        this.queryUserInfo(this.personId);
      },
      isShowField(code) {
        return !this.hideList.includes(code);
      },
      isFiledCode(code, data) {
        if(data.isCheck && data.fieldDto && data.fieldDto.fieldCode === code) return true;
        return false;
      },
      queryConfig() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/approve/field/config.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              let hideList = [];
              this.filedsConfig = data || [];
              data.forEach(item => {
                if(!item.isCheck) {
                  hideList.push(item);
                }
              })
              this.transformField(hideList, 'hideList');
            }
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },// 转换配置字段为表单字段
      transformField(data, listName) {
        this[listName] = [];
        data.forEach(item => {
          this[listName].push(item.code);
        });
      },
      queryData() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/info.json', {params: {
            websiteId: this.websiteId || '',
            jobId: this.jobId
          }}).then(data => {
            this.jobInfo = data; 
            resolve(true);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      queryDeliveryStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/job/delivery.json', {params: {
            websiteId: this.websiteId,
            recommendId: this.recommendId,
            jobId: this.jobId
          }}).then(data => {
            this.deliveryObj = data;
            resolve(true);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      queryUserInfo(personId) {
        this.$http.get('/api/internal/recom/person/info.json', {params: { personId }}).then(data => {
          if(data) {
            this.authStatus = data.authStatus;
          }
          if(data && data.authStatus) {
            local.set('oldPersonId', personId);
          } else {
            local.remove('oldPersonId');
          }
        });
      },
      // 手机号投递职位订单状态
      queryJobRecommendStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/mobile/check/info.json', {params: {
            jobId: this.jobId,
            mobile: this.mobile || '',
            email: this.email || ''
          }}).then(data => {
            if(data) {
              MessageBox.confirm('您已经在流程中，请勿重复投递', '', {
                showCancelButton: false,
                center: true
              }).then(action => {
                resolve(false);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        })
      },
      queryRecommendStatus() {
        return new Promise((resolve, reject) => {
           this.$http.get('/api/website/apply/check.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            personId: this.personId,
            mobile: this.mobile || '',
            email: this.email || ''
          }}).then(data => {
             if(data === 1){
              MessageBox.confirm('仅能有一个职位在流程中，请谨慎选择', '', {
                confirmButtonText: '确定投递',
              }).then(action => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else if(data === 2){
              MessageBox.confirm('已投递其他职位，暂不可投递', '', {
                showCancelButton: false,
                center: true
              }).then(action => {
                resolve(false);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        });
      },
      // 判断职位是否为内推职位
      isRecommendJob() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/exist.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(!data) {
               MessageBox.confirm('此职位已经下架，再看看其他职位吧', '', {
                confirmButtonText: '好的',
                showCancelButton: false,
                center: true
              }).then(action => {
                this.$router.push({
                  name: 'Home',
                  query: { id: this.websiteId },
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              });
            }
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        })
      },
      // 判断是否有更多校招站点
      querySchoolSites() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/school/interview/site/list.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              this.schoolList = data;
            }
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      openLink() {
        this.$http.get('/api/recommend/activate/open/link.json', {params: {
          optId: this.optRecordId
        }});
      },
      queryReward() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/reward/rule/info.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              this.rewardList = data;
            }
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      // 获取配置信息
      queryAuthConfig() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/auth/info.json', {params: {
            websiteId: this.websiteId
          }}).then(data => {
            if(data) {
              this.authInfoObj = data;
              // 如果从职位详情链接打开的需要校验,triggerType 触发时机 1 推荐简历时 2 检查职位前
              if(this.authInfoObj.triggerType === 2 && !this.mobile && !this.email) {
                this.loginObj.isShow = true;
                resolve(false);
              } else {
                resolve(true);
              }
            }
          }).catch((err) => {
            reject(err);
          });
        });
      },
      async onDeliver() {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(!this.mobile && !this.email) {
          if(this.$route.query.websiteId == -1) {
            this.$router.push({
              name: 'ResumeAdd',
              query: {
                jobId: this.jobInfo.id,
                websiteId: this.websiteId,
                jobCompanyId: this.jobInfo.companyId,
                accountId: this.accountId,
                optRecordId: this.optRecordId
              }
            });
          } else {
            this.loginObj.isShow = true;
          }
        } else {
          if(!this.isRecommendWebsite || !this.authStatus) {
            let result0 = await this.queryJobRecommendStatus();
            if(!result0) {
              return;
            }
            let result1 = await this.queryRecommendStatus();
            if(!result1) {
              return;
            }
          }
          let schoolResult = await this.querySchoolSites();
          if(schoolResult) {
            if(this.schoolList) {
              // 多于1个站点先选择
              if(this.schoolList.length > 1) {
                Object.assign(this.campusSitesObj, {
                  schools: this.schoolList,
                  isShow: true
                });
                return;
              }
              if(this.schoolList.length === 1) {
                this.schoolInterviewSiteId = this.schoolList[0].id
              }
            }
          }
          this.toApplyPage();
        }
      },
      toApplyPage(id) {
        this.$router.push({
          name: 'ResumeAdd',
          query: {
            jobId: this.jobInfo.id,
            websiteId: this.websiteId,
            jobCompanyId: this.jobInfo.companyId,
            accountId: this.accountId,
            optRecordId: this.optRecordId,
            schoolAmbassadorCode: this.deliveryObj ? this.deliveryObj.schoolAmbassadorCode : '',
            schoolAmbassadorName: this.deliveryObj ? this.deliveryObj.schoolAmbassadorName : '',
            schoolInterviewSiteId: id || this.schoolInterviewSiteId
          }
        });
      },
      // 撤销前的校验
      validRevokeStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/check/cancel/deliver.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            recommendId: this.deliveryObj.recommendId
          }}).then(data => {
            if(!data) {
              resolve(true);
              return;
            }
            let message = '';
            if(data.lockVolunteerFlag && data.firstChoice) {
              message = '此投递为第一志愿，确认撤回此投递吗？撤回后如果有其他投递，则志愿顺序将顺延';
            } else if(!data.lockVolunteerFlag || !data.firstChoice) {
              message = '确认撤回此投递吗？';
            }
            if(message) {
              MessageBox.confirm(message, '', {
                showCancelButton: false,
                center: true
              }).then(action => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          }).catch((err) => {
            reject(err);
          });
        });
      },
      async onRevokeDeliver() {
        let result = await this.validRevokeStatus();
        if(result) {
          this.$http.post('/api/internal/recom/person/cancel/deliver.json', {
            websiteId: this.websiteId,
            jobId: this.jobId,
            recommendId: this.deliveryObj.recommendId
          }).then(data => {
            if(data) {
              this.queryDeliveryStatus();
            } else {
              Message.error('撤销投递失败');
            }
          });
        }
      }
    }
  }
</script>