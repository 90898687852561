// 职位类型
export const JobStatus = {
  1: '开放中',
  2: '已关闭'
};

//排序类型
export const SortType = {
  1: '更新时间',
  2: '招聘进展'
};

// 职位性质
export const JobNature = {
  1: '全职',
  2: '兼职',
  3: '实习',
  4: '其他'
};

// 职位可配置显示字段
export const JobShowOptions = {
  1: '发布时间',
  2: '所属部门',
  3: '职位类别',
  5: '职位年薪',
  6: '招聘人数',
  7: '学历要求',
  8: '工作地点',
  9: '公司介绍',
  10: '职能类型',
  11: '职位级别'
};

export const InfoDimensionFilter = {
  1: '有意向沟通',
  2: '有背调',
  3: '推荐有筛选结果',
  4: '有面试评价',
  5: '面试待评价',
  6: '面试待定',
  7: '推荐待筛选',
  8: '有候选人评价'
}

export const JobPayment = {
  1: '月薪',
  2: '年薪'
}

// 性别要求
export const Gender = {
  '-1': '不限',
  '1': '男',
  '0': '女'
};

// 职位 基本信息
export const BaseField = [
  'name',
  'categoryId',
  'recruitType',
  'salaryType',
  'orgId',
  'director',
  'demand',
  'underlingNumber',
  'workPlace',
  'describe',
  'benefits',
  'nature',
  'jobClassify',
  'jobRank',
  'companyOrgId'
];
// 职位 岗位要求
export const RequireField = [
  'workYear',
  'minAge',
  'eduRequire',
  'languageRequire',
  'gender',
  'famousEnterpriseFlag',
  'businessTravel'
];
// 职位 职位设置
export const SettingField = [
  'recruitProcessId',
  'resLibraryId',
  'demandIds',
  'interviewEmailTemplateId',
  'resumeScoringRulesId',
  'resumeFilterRulesId',
  'priorityRecruitmentFlag'
];

// 招聘渠道 猎企
export const SubChannelField1 = [
  'lookContent',
  'supplierIds',
]

// 招聘渠道 内推
export const SubChannelField2 = [
  'internalRecomWebsiteIds',
  'basicInternalRecomRewardRuleId',
  'specialInternalRecomRewardRuleId'
]

// 职位 招聘渠道
export const ChannelField = [
  ...SubChannelField1,
  ...SubChannelField2
];

// 职位 负责人
export const PrincipalField = [
  'userId',
  'jurisdictionAccountIds',
  'userPersonAccountIds',
  'interviewAccountIds'
];
// 职位 候选人
export const CandidateField = [
  'interviewQuestionnaireTemplateId',
  'resAppraiseTemplateId'
];

// 字段中文名
export const FieldLabel = {
  approveFlowId: '审批流程',
  name: '职位名称',
  categoryId: '职位类别',
  recruitType: '招聘类型',
  salaryType: '薪资范围',
  minSalary: '',

  orgId: '所属部门',
  director: '汇报对象',
  demand: '招聘人数',
  underlingNumber: '下属人数',
  workPlace: '工作地点',
  describe: '岗位描述',
  benefits: '职位亮点',
  nature: '职位性质',
  jobClassifyId: '职能类型',
  jobRankIds: '职位级别',
  companyOrgId: '公司名称',
  tagIds: '职位标签',
  
  workYear: '工作年限',
  minAge: '年龄要求',
  eduRequire: '学历要求',
  languageRequire: '语言要求',
  gender: '性别要求',
  famousEnterpriseFlag: 'slot',
  businessTravel: '是否需要出差',

  recruitProcessId: '招聘流程',
  resLibraryId: 'slot',
  schoolInterviewSiteIds: '校招面试站点',
  demandIds: '关联招聘需求',
  interviewEmailTemplateId: '候选人面试通知模版',
  resumeScoringRulesId: '简历评分规则',
  resumeFilterRulesId: '简历自动筛选规则',
  priorityRecruitmentFlag: '优先招聘',

  lookContent: '寻访要求',
  supplierIds: '委托猎企',
  internalRecomWebsiteIds: '发布到官网',
  basicInternalRecomRewardRuleId: '内推基础奖励规则',
  specialInternalRecomRewardRuleId: '内推特殊奖励规则',
  
  userId: '招聘负责人',
  jurisdictionAccountIds: '招聘协助人',
  userPersonAccountIds: '用人经理',
  qwContactId: '招聘联络人',
  interviewAccountIds: '面试官',

  interviewQuestionnaireTemplateId: '面试登记表',
  registSmsFlag: '短信推送给候选人',
  feedbackFlag: '面试反馈',
  noticeFileId: '面试须知',
  backgroundAuditFlag: '面试审计',
}

// 接口字段转换
export const FieldNameTransform = {
  jobSalary: [
    'salaryType',
    'minSalary',
    'maxSalary',
    'monthSalaryType'
  ],
  jobClassify: 'jobClassifyId',
  jobRank: 'jobRankIds',
  age: [
    'minAge',
    'maxAge'
  ],
  department: 'orgId',
  address: 'workPlace',
  internalRecom: 'internalRecomWebsiteIds',
  userPersonAccountIds: [
    'userPersonAccountIds',
    'autoPushFlag'
  ],
  interviewer: [
    'priorityType',
    'interviewAccountIds',
    'interviewAppraiseTemplateId',
    'secondInterviewAccountIds',
    'secondInterviewAppraiseTemplateId',
    'lastInterviewAccountIds',
    'lastInterviewAppraiseTemplateId'
  ],
  feedback: [
    'feedbackFlag',
    'resPriorityType',
    'resAppraiseTemplateId',
    'secondResAppraiseTemplateId',
    'lastResAppraiseTemplateId'
  ]
}

export const EduRequireExtend = {
  'collegeToUniversity': '非专升本',
  'nationalUnified': '必须统招',
  'famousUniversityFlag': '985/211'
}

export const JobListFilterConfig = {
  1: '职位名称',
  2: '工作地点',
  3: '职位部门',
  4: '职位性质',
  5: '招聘负责人',
  6: '招聘协助人',
  7: '职能类型',
  8: '发布时间',
  9: '职位年薪',
  10: '职位标签'
}

export default {
  JobStatus,
  SortType,
  JobNature,
  JobShowOptions,
  InfoDimensionFilter,
  JobPayment,
  Gender,
  BaseField,
  RequireField,
  SettingField,
  ChannelField,
  PrincipalField,
  CandidateField,
  FieldLabel,
  FieldNameTransform,
  EduRequireExtend,
  JobListFilterConfig,
  SubChannelField1,
  SubChannelField2
}
