<template>
  <sub-h5-job-list v-if="$utils.isPhone()"></sub-h5-job-list>
  <sub-pc-job-list v-else></sub-pc-job-list>
</template>

<script>
  import subH5JobList from '@/views/submodule/h5/jobList'
  import subPcJobList from '@/views/submodule/pc/jobList'
  export default {
    components: {
      subH5JobList,
      subPcJobList
    }
  }
</script>