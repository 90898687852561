<template>
  <el-popover
    placement="bottom-end"
    title=""
    width="210"
    popper-class="popper-create-share"
    trigger="click">
    <div class="share-content">
      <p class="share-txt">扫码分享职位</p>
      <div class="share-img" id="qrCode" ref="qrCode"></div>
      <p class="share-txt">或使用链接分享</p>
      <el-input v-model="shareUrl" size="small" class="input-copy">
        <el-button slot="append" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess">复制链接</el-button>
      </el-input>
    </div>
    <div slot="reference" class="icon-share">分享<i class="h5pp h5pp-icon-share"></i></div>
  </el-popover>
</template>

<script>
  import './index.scss'
  import QRCode from 'qrcodejs2'
  import { mapGetters } from 'vuex';
  import { Popover, Input, Button, Message } from 'element-ui'
  import { local } from '@/utils'

  export default {
    components: {
      [Popover.name]: Popover,
      [Input.name]: Input,
      [Button.name]: Button
    },
    props: {
      jobId: {
        type: [String, Number],
        default: ''
      },
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || local.get('oldPersonId') || '';
      }
    },
    data() {
      return {
        shareUrl: location.href,
        isShow: false
      }
    },
    created() {
      if(this.personId) {
        this.shareUrl = `${window.location.origin}/job/list?websiteId=${this.uccnInfo.id}&personId=${this.personId}`;
        if(this.jobId) {
          this.shareUrl = `${window.location.origin}/job/detail/${this.jobId}?websiteId=${this.uccnInfo.id}&personId=${this.personId}`;
        }
      }
      this.createdQrcode();
    },
    methods: {
      createdQrcode() {
        this.$nextTick(() => {
          new QRCode('qrCode',{
            width: 180, 
            height: 180,
            text: this.shareUrl
          })
        });
      },
      onCopySuccess() {
        this.$utils.sendStat('0065', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId,
          jobId: this.jobId
        });
        Message.success('复制成功！');
      }
    }
  }
</script>