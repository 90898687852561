<template>
  <div class="h5-container">
    <sub-head-navs :title="isRecommendWebsite ? '内推职位' : '职位列表'"></sub-head-navs>
    <div class="job-list-wrap">
      <van-sticky>
        <sub-recommend-people v-if="isRecommendWebsite"/>
        <div class="query-condition">
          <van-search v-model.trim="searchParams.keyWord" placeholder="请输入职位关键字" class="input-search" left-icon="" input-align="center" @search="onReQuery()"/>
          <i class="h5pp h5pp-icon-funnel" @click="isShowSearch = true"><span>筛选</span></i>
        </div>
      </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onReQuery">
        <van-list class="job-list" v-model="isLoading" :finished="finished" finished-text="没有更多了" @load="queryData()">
          <van-cell v-for="(item, key) in jobList" :key="key">
            <a @click="isLogin(item.id)" href="javascript:;">
              <dl class="item-job">
                <dt v-if="item.jobName" class="clearfix">
                  <h3 class="title el-sl"><i v-if="item.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" />{{ item.jobName }}</h3>
                  <p v-if="item.minSalary && item.maxSalary && showFieldList.includes(5)" class="salary">
                    <template v-if="item.salaryType === 1">{{ item.minSalary }}K-{{ item.maxSalary }}K・{{ item.monthSalaryType }}<span v-if="item.monthSalaryType>12">薪</span></template>
                    <template v-else>{{ item.minSalary }}W-{{ item.maxSalary }}W</template>
                  </p>
                </dt>
                <dd>
                  <ul v-if="item.department || item.categoryId || item.jobClassifyName || item.jobRankNames" class="job-divide-info clearfix">
                    <li v-if="item.department && showFieldList.includes(2)" class="org-name el-sl">{{ item.department }}</li>
                    <li v-if="item.categoryId && showFieldList.includes(3)" class="functions el-sl">{{ item.categoryId | FUNCTION }}</li>
                    <li v-if="item.jobClassifyName && showFieldList.includes(10)" class="job-classify el-sl">{{ item.jobClassifyName }}</li>
                    <li v-if="item.jobRankNames && item.jobRankNames.length  && showFieldList.includes(11)" class="rank el-sl">{{ item.jobRankNames.join(',') }}</li>
                  </ul>
                  <ul v-if="(item.eduRequire || item.city) && showFieldList.some((ele) => [4, 7, 8].includes(ele))" class="job-divide-info address-box clearfix">
                    <li v-if="item.eduRequire && showFieldList.includes(7)">{{ item.eduRequire | JOBDEGREE }}</li>
                    <li v-if="item.city && showFieldList.includes(8)" class="el-sl">{{ item | FULLADDESS }}</li>
                  </ul>
                  <ul v-if="(item.demand || item.modifyTime) && showFieldList.some((ele) => [1, 6].includes(ele))" class="job-between-info">
                    <li v-if="item.demand && showFieldList.includes(6)">招聘人数：{{ item.demand }}人</li>
                    <li v-if="item.modifyTime && showFieldList.includes(1)" class="create-date">发布时间：{{ item.modifyTime | DATE('YYYY-MM-DD') }}</li>
                  </ul>
                </dd>
              </dl>
            </a>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <popup-share-menus />
    </div>
    <!-- 挂载到 #app 节点下 -->
    <van-popup class="popup-search-box" v-model="isShowSearch" position="right" :close-on-click-overlay="false" get-container="#app">
      <sub-search-condition :queryParams="searchParams" ref="condition" @close="isShowSearch = false" @search="onReQuery()"></sub-search-condition>
    </van-popup>
  </div>
</template>

<script>
  import './index.scss'
  import wxapi from '@/utils/wxapi'
  import { mapGetters } from 'vuex'
  import { Sticky, Search, PullRefresh, List, Cell, Popup } from 'vant'
  import { local } from '@/utils'
  import subHeadNavs from '@/components/h5/headNavs'
  import subSearchCondition from './searchCondition'
  import subRecommendPeople from '@/components/h5/recommendPeople'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      [Sticky.name]: Sticky,
      [Search.name]: Search,
      [PullRefresh.name]: PullRefresh,
      [List.name]: List,
      [Cell.name]: Cell,
      [Popup.name]: Popup,
      subHeadNavs,
      subSearchCondition,
      subRecommendPeople,
      popupShareMenus
    },
    data() {
      return {
        isLoading: false,
        finished: false,
        refreshing: false,
        searchParams: {
          websiteId: '',
          keyWord: '',
          city: '',
          companyWorkSpaceId: '',
          categoryId: '',
          eduRequire: '',
          jobClassifyId: '',
          jobRankId: '',
          orgId: '',
          current: 0,
          pageSize: 20
        },
        jobList: [],
        showFieldList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        isShowSearch: false,
        authInfoObj: {}
      }
    },
    provide() {
      return {
        pageFrom: 'jobList'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      }
    },
    created() {
      this.searchParams.websiteId = this.$route.query.websiteId || '';
      this.searchParams.keyWord = this.$route.query.keyWord || '';
      this.searchParams.categoryId = this.$route.params.categoryId || '';
      this.searchParams.jobClassifyId = this.$route.params.jobClassifyId || undefined;
      this.searchParams.jobRankId = this.$route.params.jobRankId || undefined;
      this.searchParams.cityCode = this.$route.params.cityCode || undefined;
      this.searchParams.orgId = this.$route.params.orgId || '';
      this.searchParams.companyWorkSpaceId = this.$route.params.companyWorkSpaceId || undefined;
      this.searchParams.moduleId = +this.$route.params.moduleId || undefined;
      this.init();
      if(this.$route.query.personId) {
        this.queryUserInfo(this.$route.query.personId);
      }
    },
    methods: {
      async init() {
        this.isLoading = true;
        if(this.isRecommendWebsite) {
          this.queryAuthConfig();
        }
        if(this.searchParams.websiteId > 0) {
          this.queryConfigField();
        }
        this.queryData();
        this.setShareConfig();
      },
      queryConfigField() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/config.json', {params: {
            websiteId: this.searchParams.websiteId
          }}).then(data => {
            this.showFieldList = data || []; 
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },
      queryData() {
        return new Promise((resolve, reject) => {
          this.searchParams.current++;
          this.isShowSearch = false;
          if(this.$refs['condition'] && this.$refs['condition'].$data) {
            this.searchParams = Object.assign({}, this.searchParams, {
              companyWorkSpaceId: this.$refs['condition'].$data.searchParams.companyWorkSpaceId,
              categoryId: this.$refs['condition'].$data.searchParams.categoryId,
              eduRequire: this.$refs['condition'].$data.searchParams.eduRequire,
              jobClassifyId: this.$refs['condition'].$data.searchParams.jobClassifyId,
              jobRankId: this.$refs['condition'].$data.searchParams.jobRankId,
              orgId: this.$refs['condition'].$data.searchParams.orgId,
            });
          }
          this.isLoading = true;
          let queryParams = this.$utils.deleteKeys(Object.assign({}, this.searchParams, {
            websiteId: this.searchParams.websiteId || ''
          }));
          this.$http.get('/api/website/job/list.json', {params: {
            ...queryParams
          }}).then(data => {
            if(data && data.list && data.list.length) {
              this.jobList = [...this.jobList, ...data.list];
            }
            if(this.jobList.length >= data.totalRecords) {
              this.finished = true;
            }
            resolve(true);
          }).catch(err => {
            this.finished = true;
            reject(err);
          }).finally(() => {
            // 加载状态结束
            this.isLoading = false;
            if(this.refreshing) {
              this.refreshing = false;
            }
          });
        });
      },
      // 获取配置信息
      queryAuthConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.searchParams.websiteId
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      queryUserInfo(personId) {
        this.$http.get('/api/internal/recom/person/info.json', {params: { personId }}).then(data => {
          if(data && data.authStatus) {
            local.set('oldPersonId', personId);
          } else {
            local.remove('oldPersonId');
          }
        });
      },
      isLogin(id) {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(this.authInfoObj.triggerType === 2 && !this.mobile && !this.email) {
          this.$router.push({ name: 'login', query: { 
            routeName: 'jobList',
            websiteId: this.searchParams.websiteId
          }});
        } else {
          this.$router.push({
            name: 'jobDetail',
            params: { id },
            query: { websiteId: this.searchParams.websiteId },
          });
        }
      },
      onReQuery() {
        // 清空列表数据
        this.finished = false;
        this.jobList = [];
        this.searchParams.current = 0;
        // 重新加载数据
        this.queryData();
      },
      setShareConfig() {
        if(this.uccnInfo) {
          wxapi.wxRegister({
            title: this.uccnInfo.shareMainTitle || `${this.uccnInfo.companyName}-加入我们`, // 分享标题
            desc: this.uccnInfo.shareSubTitle, // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.uccnInfo.shareImageUrl, // 分享图标
            success: () => {}
          });
        }
      }
    }
  }
</script>