<template>
  <div class="h5-container">
    <sub-head-navs v-if="websiteId>0" title="职位详情"></sub-head-navs>
    <sub-recommend-people v-if="isRecommendWebsite" />
    <div class="job-detail-wrap">
      <dl class="job-base">
        <dt class="h-title">
          <span><i v-if="jobInfo.priorityRecruitmentFlag" class="h5pp h5pp-icon-flame" /><template v-if="jobInfo.jobName && isShowField('name')">{{ jobInfo.jobName }}</template></span>
          <span v-if="jobInfo.minSalary && jobInfo.maxSalary && isShowField('jobSalary')" class="salary">
            <template v-if="jobInfo.salaryType === 1">{{ jobInfo.minSalary }}-{{ jobInfo.maxSalary }}K・{{ jobInfo.monthSalaryType }}</template>
            <template v-else>{{ jobInfo.minSalary }}-{{ jobInfo.maxSalary }}W</template>
          </span>
        </dt>
        <dd>
          <ul v-if="jobInfo.city && isShowField('address') || (jobInfo.workYear || jobInfo.workYear == 0) && isShowField('workYear') || jobInfo.eduRequire && isShowField('eduRequire') || jobInfo.nature && isShowField('nature')" class="job-divide-info clearfix">
            <li v-if="jobInfo.city && isShowField('address')">{{ jobInfo.city | FULLCITY('pcd', '·') }}</li>
            <li v-if="(jobInfo.workYear || jobInfo.workYear == 0) && isShowField('workYear')">{{ jobInfo.workYear | JOBWORKYEARS }}</li>
            <li v-if="jobInfo.eduRequire && isShowField('eduRequire')">{{ jobInfo.eduRequire | JOBDEGREE }}</li>
            <li v-if="jobInfo.nature && isShowField('nature')">{{ JobNature[jobInfo.nature] }}</li>
          </ul>
          <ul v-if="jobInfo.modifyTime" class="job-between-info">
            <li class="create-date">发布时间：{{ jobInfo.modifyTime | DATE('YYYY-MM-DD') }}</li>
          </ul>
        </dd>
      </dl>
      <div class="job-content">
        <div v-if="Object.keys(rewardList).length" class="job-module">
          <div class="module-title">内推奖励规则</div>
          <div v-if="rewardList.basicRuleExplain" class="module-item"><div class="label"><p>基础奖励</p>：</div><div class="content" v-html="$n2br(rewardList.basicRuleExplain)"></div></div>
          <div v-if="rewardList.specialRuleExplain" class="module-item"><div class="label"><p>特殊奖励</p>：</div><div class="content" v-html="$n2br(rewardList.specialRuleExplain)"></div></div>
        </div>
        <div class="job-module">
          <div class="module-title">基本信息</div>
          <template v-for="(field, fieldKey) in filedsConfig">
            <div v-if="isFiledCode('department', field) && jobInfo.department" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.department }}</div></div>
            <div v-else-if="isFiledCode('categoryId', field) && jobInfo.categoryId" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.categoryName }}</div></div>
            <div v-else-if="isFiledCode('director', field) && jobInfo.director" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.director }}</div></div>
            <div v-else-if="isFiledCode('demand', field) && jobInfo.demand" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.demand }}人</div></div>
            <div v-else-if="isFiledCode('underlingNumber', field) && jobInfo.underlingNumber >= 0" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.underlingNumber }}</div></div>
            <div v-else-if="isFiledCode('address', field) && (jobInfo.city || jobInfo.address)" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.city + '' | FULLCITY('p-c-d') }}{{ jobInfo.address }}</div></div>
            <div v-else-if="isFiledCode('describe', field) && jobInfo.describe" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content" v-html="$options.filters.NEWLINE(jobInfo.describe)"></div></div>
            <div v-else-if="isFiledCode('benefits', field) && jobInfo.positionBenefits && jobInfo.positionBenefits.length" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div>
              <div class="content">
                <div v-for="(obj, index) in jobInfo.positionBenefits" :key="index" class="tag-item">{{ obj }}</div>
              </div>
            </div>
            <div v-else-if="isFiledCode('jobClassify', field) && jobInfo.jobClassifyName" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.jobClassifyName }}</div></div>
            <div v-else-if="isFiledCode('jobRank', field) && jobInfo.jobRankNames && jobInfo.jobRankNames.length" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.jobRankNames.join(' ') }}</div></div>
            <div v-else-if="isFiledCode('companyOrgId', field) && jobInfo.companyOrgName" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.companyOrgName }}</div></div>
            <div v-else-if="isFiledCode('tagIds', field) && jobInfo.tagName && jobInfo.tagName.length" class="module-item"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div>
              <div class="content">
                <div v-for="(obj, index) in jobInfo.tagName" :key="index" class="tag-item">{{ obj }}</div>
              </div>
            </div>
            <sub-custom-field v-if="field.isCheck && getFieldVal(field.fieldId, jobInfo.fieldMap)" :fieldMap="jobInfo.fieldMap" :field="field" :key="fieldKey"/>
          </template>
        </div>
        <div v-if="(isShowField('age') && jobInfo.minAge && jobInfo.maxAge) || (isShowField('languageRequire') && jobInfo.languageRequire) || (isShowField('gender') && [-1, 0, 1].includes(+jobInfo.gender)) || (isShowField('businessTravel') && jobInfo.businessTravel)" class="job-module">
          <div class="module-title">岗位要求</div>
          <template v-for="(field, fieldKey) in filedsConfig">
            <div v-if="isFiledCode('age', field) && jobInfo.minAge && jobInfo.maxAge" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.minAge }}-{{ jobInfo.maxAge }}岁</div></div>
            <div v-else-if="isFiledCode('languageRequire', field) && jobInfo.languageRequire" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.languageRequire }}</div></div>
            <div v-else-if="isFiledCode('gender', field) && [-1, 0, 1].includes(+jobInfo.gender)" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ Gender[jobInfo.gender] }}</div></div>
            <div v-else-if="isFiledCode('businessTravel', field) && jobInfo.businessTravel" class="module-item inline"><div class="label"><p>{{ field.fieldDto.name }}</p>：</div><div class="content">{{ jobInfo.businessTravel }}</div></div>
          </template>
        </div>
        <div v-if="jobInfo.introduce" class="job-module">
          <div class="module-title">公司信息</div>
          <div class="module-item">
            <div class="content" v-html="$options.filters.NEWLINE(jobInfo.introduce)"></div>
          </div>
        </div>
      </div>
      <van-tabbar class="delivery-resume">
        <span v-if="deliveryObj && Object.keys(deliveryObj).length" class="delivery-box">
          <template v-if="deliveryObj.withdrawFlag || deliveryObj.editFlag">
            <a v-if="deliveryObj.withdrawFlag" @click="onRevokeDeliver" href="javascript:;" class="edit-btn">撤销投递</a>
            <router-link v-if="deliveryObj.editFlag" :to="{
              name: 'myResume',
              query: {
                jobId: jobInfo.id,
                websiteId: websiteId,
                jobCompanyId: jobInfo.companyId,
                accountId: accountId,
                optRecordId: optRecordId,
                schoolAmbassadorCode: deliveryObj.schoolAmbassadorCode,
                schoolAmbassadorName: deliveryObj.schoolAmbassadorName
              }
            }" class="edit-btn">修改信息</router-link>
          </template>
          <span v-else class="deliveired">已投递</span>
          <span class="delivery-status el-sl">状态：{{ deliveryObj.resultName }}</span>
        </span>
        <a v-else @click="onDeliver" href="javascript:;" class="diy-btn">投递简历</a>
      </van-tabbar>
      <popup-campus-sites v-if="campusSitesObj.isShow" :dialogData="campusSitesObj" @success="toApplyPage" />
      <popup-share-menus :jobId="jobId" :jobName="jobInfo.jobName" />
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import wxapi from '@/utils/wxapi'
  import { mapGetters } from 'vuex'
  import { Tabbar, Dialog, Toast } from 'vant'
  import { local } from '@/utils'
  import { JobNature, Gender } from '@/enums/EnumJob'
  import jobDetailMix from './mixin'
  import subHeadNavs from '@/components/h5/headNavs'
  import subRecommendPeople from '@/components/h5/recommendPeople'
  import subCustomField from './submodule/customField'
  import popupShareMenus from '@/components/h5/shareMenus'
  import popupCampusSites from '@/components/h5/dialog/campusSites'

  export default {
    mixins: [jobDetailMix],
    components: {
      [Tabbar.name]: Tabbar,
      subHeadNavs,
      subRecommendPeople,
      subCustomField,
      popupShareMenus,
      popupCampusSites
    },
    data() {
      return {
        JobNature,
        Gender,
        isLoading: false,
        jobId: '',
        websiteId: '',
        accountId: '',
        optRecordId: '',
        recommendId: '',
        jobInfo: {},
        rewardList: {},
        showFieldList: [],
        authInfoObj: {},
        deliveryObj: {},
        authStatus: 0,
        personId: '',
        schoolList: [],
        schoolInterviewSiteId: '',
        campusSitesObj: { isShow: false },
        hideList: [],
        filedsConfig: []
      }
    },
    provide() {
      return {
        pageFrom: 'jobDetail',
        jobId: this.$route.params.id
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      isRecommendWebsite() {
        return (this.$route.query.websiteId > 0 && local.get('uccnInfo') && (JSON.parse(local.get('uccnInfo') || '{}')).type) || '';
      }
    },
    created() {
      this.jobId = this.$route.params.id || '';
      this.websiteId = this.$route.query.websiteId || '';
      this.accountId = this.$route.query.accountId || '';
      this.optRecordId = this.$route.query.optRecordId || '';
      this.recommendId = this.$route.query.recommendId || '';
      this.init();
    },
    mounted() {
      if(this.isRecommendWebsite) {
        this.$utils.sendStat('0064', {
          webSiteId: this.websiteId,
          personId: this.personId,
          jobId: this.jobId
        });
      }
    },
    methods: {
      async init() {
        this.isLoading = true;
        // 登录之后调用状态
        if(this.mobile || this.email) {
          this.queryDeliveryStatus();
        }
        if(this.isRecommendWebsite) {
          let result = await this.queryAuthConfig();
          if(result) {
            Promise.all([
              this.queryConfig(),
              this.queryData(),
              this.queryReward(),
              this.isRecommendJob()
            ]).then(res => {
              this.isLoading = false;
            });
          }
        } else {
          Promise.all([
            this.queryConfig(),
            this.queryData()
          ]).then(res => {
            this.isLoading = false;
          });
        }
        if(this.optRecordId) {
          // 标记邮箱中已打开查看职位的链接
          this.openLink();
        }
        if(this.$route.query.personId) {
          this.personId = this.$route.query.personId;
          this.queryUserInfo(this.$route.query.personId);
        }
      },
      isShowField(code) {
        return !this.hideList.includes(code);
      },
      isFiledCode(code, data) {
        if(data.isCheck && data.fieldDto && data.fieldDto.fieldCode === code) return true;
        return false;
      },
      queryConfig() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/approve/field/config.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              let hideList = [];
              this.filedsConfig = data || [];
              data.forEach(item => {
                if(!item.isCheck) {
                  hideList.push(item);
                }
              })
              this.transformField(hideList, 'hideList');
            }
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },// 转换配置字段为表单字段
      transformField(data, listName) {
        this[listName] = [];
        data.forEach(item => {
          this[listName].push(item.code);
        });
      },
      queryData() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/info.json', {params: {
            websiteId: this.websiteId || '',
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              this.jobInfo = data;
            }
            this.setShareConfig();
            resolve(true);
          });
        });
      },
      queryReward() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/reward/rule/info.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              this.rewardList = data;
            }
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      // 获取配置信息
      queryAuthConfig() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/auth/info.json', {params: {
            websiteId: this.websiteId
          }}).then(data => {
            if(data) {
              this.authInfoObj = data;
              // 如果从职位详情链接打开的需要校验,triggerType 触发时机 1 推荐简历时 2 检查职位前
              if(this.authInfoObj.triggerType === 2 && !this.mobile && !this.email) {
                this.$router.push({
                  name: 'login',
                  query: {
                    routeName: 'jobDetail',
                    websiteId: this.websiteId,
                    jobId: this.jobId
                  }
                });
                resolve(false);
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }
          }).catch((err) => {
            reject(err);
          });
        });
      },
      queryDeliveryStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/job/delivery.json', {params: {
            websiteId: this.websiteId,
            recommendId: this.recommendId,
            jobId: this.jobId
          }}).then(data => {
            this.deliveryObj = data; 
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        })
      },
      queryUserInfo(personId) {
        this.$http.get('/api/internal/recom/person/info.json', {params: { personId }}).then(data => {
          if(data) {
            this.authStatus = data.authStatus;
          }
          if(data && data.authStatus) {
            local.set('oldPersonId', personId);
          } else {
            local.remove('oldPersonId');
          }
        });
      },
      // 手机号投递职位订单状态
      queryJobRecommendStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/mobile/check/info.json', {params: {
            jobId: this.jobId,
            mobile: this.mobile || '',
            email: this.email || ''
          }}).then(data => {
            if(data) {
              Dialog.alert({
                message: '您已经在流程中，请勿重复投递',
              }).then(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        })
      },
      queryRecommendStatus() {
        return new Promise((resolve, reject) => {
           this.$http.get('/api/website/apply/check.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            personId: this.personId,
            mobile: this.mobile || '',
            email: this.email || ''
          }}).then(data => {
            if(data === 1){
              Dialog.confirm({
                message: '仅能有一个职位在流程中，请谨慎选择',
                confirmButtonText: '确定投递'
              }).then(() => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else if(data === 2){
              Dialog.alert({
                message: '已投递其他职位，暂不可投递',
              }).then(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          });
        });
      },
      // 判断职位是否为内推职位
      isRecommendJob() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/exist.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(!data) {
              Dialog.alert({
                message: '此职位已经下架，再看看其他职位吧',
                confirmButtonText: '好的'
              }).then(() => {
                this.$router.push({
                  name: 'Home',
                  query: { id: this.websiteId },
                  params: { pathMatch: local.get('rootPath') || '' }
                });
              });
              resolve(true);
            }
          }).catch((err) => {
            reject(err);
          });
        })
      },
      // 判断是否有更多校招站点
      querySchoolSites() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/school/interview/site/list.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId
          }}).then(data => {
            if(data) {
              this.schoolList = data;
            }
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        });
      },
      async onDeliver() {
        // 登录,triggerType 触发时机 1 推荐简历时 2 检查职位前
        if(!this.mobile && !this.email) {
          if(this.$route.query.websiteId == -1) {
            this.$router.push({ 
              name: 'ResumeAdd',
              query: {
                jobId: this.jobId,
                websiteId: this.websiteId,
                jobCompanyId: this.jobInfo.companyId,
                accountId: this.accountId,
                optRecordId: this.optRecordId,
              }
            });
          } else {
            this.$router.push({
              name: 'login',
              query: {
                routeName: 'jobDetail',
                websiteId: this.websiteId,
                jobId: this.jobId
              }
            });
          }
        } else {
          if(!this.isRecommendWebsite || !this.authStatus) {
            let result0 = await this.queryJobRecommendStatus();
            if(!result0) {
              return;
            }
            let result1 = await this.queryRecommendStatus();
            if(!result1) {
              return;
            }
          }

          let schoolResult = await this.querySchoolSites();
          if(schoolResult) {
            if(this.schoolList) {
              // 多于1个站点先选择
              if(this.schoolList.length > 1) {
                Object.assign(this.campusSitesObj, {
                  schools: this.schoolList,
                  isShow: true
                });
                return;
              }
              if(this.schoolList.length === 1) {
                this.schoolInterviewSiteId = this.schoolList[0].id
              }
            }
          }
          this.toApplyPage();
        }
      },
      toApplyPage(id) {
        this.$router.push({
          name: 'ResumeDelivery',
          query: {
            jobId: this.jobInfo.id,
            websiteId: this.websiteId,
            jobCompanyId: this.jobInfo.companyId,
            accountId: this.accountId,
            optRecordId: this.optRecordId,
            schoolAmbassadorCode: this.deliveryObj ? this.deliveryObj.schoolAmbassadorCode : '',
            schoolAmbassadorName: this.deliveryObj ? this.deliveryObj.schoolAmbassadorName : '',
            schoolInterviewSiteId: id || this.schoolInterviewSiteId
          }
        });
      },
      openLink() {
        this.$http.get('/api/recommend/activate/open/link.json', {params: {
          optId: this.optRecordId
        }});
      },
      setShareConfig() {
        if(this.uccnInfo) {
          wxapi.wxRegister({
            title: this.uccnInfo.shareMainTitle || this.jobInfo.jobName, // 分享标题
            desc: this.uccnInfo.shareSubTitle, // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.uccnInfo.shareImageUrl, // 分享图标
            success: () => {}
          });
        }
      },
      // 撤销前的校验
      validRevokeStatus() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/internal/recom/person/check/cancel/deliver.json', {params: {
            websiteId: this.websiteId,
            jobId: this.jobId,
            recommendId: this.deliveryObj.recommendId
          }}).then(data => {
            if(!data) {
              resolve(true);
              return;
            }
            let message = '';
            if(data.lockVolunteerFlag && data.firstChoice) {
              message = '此投递为第一志愿，确认撤回此投递吗？撤回后如果有其他投递，则志愿顺序将顺延';
            } else if(!data.lockVolunteerFlag || !data.firstChoice) {
              message = '确认撤回此投递吗？';
            }
            if(message) {
              Dialog.confirm({ message }).then(() => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              });
            } else {
              resolve(true);
            }
          }).catch((err) => {
            reject(err);
          });
        });
      },
      async onRevokeDeliver() {
        let result = await this.validRevokeStatus();
        if(result) {
          this.$http.post('/api/internal/recom/person/cancel/deliver.json', {
            websiteId: this.websiteId,
            jobId: this.jobId,
            recommendId: this.deliveryObj.recommendId
          }).then(data => {
            if(data) {
              this.queryDeliveryStatus();
            } else {
              Toast.fail('撤销投递失败');
            }
          });
        }
      }
    },
    watch: {
      isRecommendWebsite(val) {
        return val;
      }
    }
  }
</script>