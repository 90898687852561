<template>
  <van-action-sheet 
    title="选择校招站点" 
    v-model="dialogData.isShow" 
    close-on-click-action 
    class="dialog-campus-sites"
    :closeable="false">
    <van-radio-group v-model="schoolInterviewSiteId">
      <!-- <van-radio v-for="(item, key) in schoolSiteList" :key="key" :name="item.id" shape="square">{{ item.name }}</van-radio> -->
      <van-cell v-for="(item, key) in schoolSiteList" :key="key" :title="item.name" clickable @click="schoolInterviewSiteId = item.id">
        <template #right-icon><van-radio :name="item.id" shape="square" /></template>
      </van-cell>
    </van-radio-group>
    <div class="footer-btns">
      <van-button type="default" @click="dialogData.isShow = false">取消</van-button>
      <van-button type="primary" @click="onSave">确认</van-button>
    </div>
  </van-action-sheet>
</template>
<script>
  import './index.scss'
  import { ActionSheet, RadioGroup, Radio, Cell, Button, Toast } from 'vant'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [ActionSheet.name]: ActionSheet,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Cell.name]: Cell,
      [Button.name]: Button
    },
    data() {
      return {
        schoolSiteList: [],
        schoolInterviewSiteId: ''
      }
    },
    created() {
      this.schoolSiteList = this.dialogData.schools;
    },
    methods: {
      onSave() {
        if(!this.schoolInterviewSiteId) {
          Toast.fail('请先选择校招站点');
          return;
        }
        this.$emit('success', this.schoolInterviewSiteId);
        this.dialogData.isShow = false;
      }
    }
  }
</script>
