<template>
  <div class="search-condition-box">
    <van-loading v-if="isLoading" class="loading" color="#0055DC" />
    <div v-else-if="!isLoading" class="search-condition">
      <div v-if="showSearchList.includes(8)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>工作地点</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('companyWorkSpaceId')">
            <template v-if="switchObj['companyWorkSpaceId']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['companyWorkSpaceId']" class="item-condition">
          <van-row gutter="20">
            <van-col v-for="(item, key) in cityList" :key="key" span="24">
              <p class="el-sl address-text" :class="{active: `${item.id}` === searchParams.companyWorkSpaceId}" @click="onCheck('companyWorkSpaceId', `${item.id}`)">{{ item | FULLADDESS }}</p>
            </van-col>
          </van-row>
        </div> 
      </div>
      <div v-if="showSearchList.includes(3)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>职位类别</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('functions')">
            <template v-if="switchObj['functions']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['functions']" class="item-condition">
          <van-row gutter="20">
            <template v-for="(item, key) in categoryList">
              <van-col :key="key" span="8">
                <p class="el-sl" :class="{active: `${item}` === searchParams.categoryId}" @click="onCheck('categoryId', `${item}`)">{{ item | FUNCTIONALIAS }}</p>
              </van-col>
            </template>
          </van-row>
        </div> 
      </div>
      <div v-if="showSearchList.includes(10)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>职能类型</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('jobClassifyId')">
            <template v-if="switchObj['jobClassifyId']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['jobClassifyId']" class="item-condition">
          <van-row gutter="20">
            <template v-for="(item, key) in jobClassifyList">
              <van-col :key="key" span="8">
                <p class="el-sl" :class="{active: `${item.id}` === searchParams.jobClassifyId}" @click="onCheck('jobClassifyId', `${item.id}`)">{{ item.name }}</p>
              </van-col>
            </template>
          </van-row>
        </div> 
      </div>
       <div v-if="showSearchList.includes(11)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>职位级别</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('jobRankIds')">
            <template v-if="switchObj['jobRankIds']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['jobRankIds']" class="item-condition">
          <van-row gutter="20">
            <template v-for="(item, key) in jobLevel">
              <van-col :key="key" span="8">
                <p class="el-sl" :class="{active: `${item.id}` === searchParams.jobRankId}" @click="onCheck('jobRankId', `${item.id}`)">{{ item.rankName }}</p>
              </van-col>
            </template>
          </van-row>
        </div> 
      </div>
      <div v-if="showSearchList.includes(2)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>所属部门</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('orgId')">
            <template v-if="switchObj['orgId']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['orgId']" class="item-condition">
          <van-row gutter="20">
            <template v-for="(item, key) in orgList">
              <van-col :key="key" span="8">
                <p class="el-sl" :class="{active: `${item.id}` === searchParams.orgId}" @click="onCheck('orgId', `${item.id}`)">{{ item.name }}</p>
              </van-col>
            </template>
          </van-row>
        </div> 
      </div>
      <div v-if="showSearchList.includes(7)" class="item-condition-box">
        <div class="item-condition-title clearfix">
          <h4>学历要求</h4>
          <a href="javascript:;" class="switch-show" @click="onSwitch('eduRequire')">
            <template v-if="switchObj['eduRequire']">收起<van-icon name="arrow-up" /></template>
            <template v-else>展开<van-icon name="arrow-down" /></template>
          </a>
        </div>
        <div v-show="switchObj['eduRequire']" class="item-condition">
          <van-row gutter="20">
            <van-col v-for="(item, key) in educations" :key="key" span="8">
              <p class="el-sl" :class="{active: `${item.code}` === searchParams.eduRequire}" @click="onCheck('eduRequire', `${item.code}`)">{{ item.name }}</p>
            </van-col>
          </van-row>
        </div> 
      </div>
    </div>
    <van-tabbar>
      <van-button type="default" @click="$emit('close')">取消</van-button>
      <van-button type="primary" @click="$emit('search')">确认</van-button>
    </van-tabbar>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Loading, Tabbar, Icon, Row, Col, Tag, Button } from 'vant'
  import { local } from '@/utils'

  export default {
    components: {
      [Loading.name]: Loading,
      [Tabbar.name]: Tabbar,
      [Icon.name]: Icon,
      [Row.name]: Row,
      [Col.name]: Col,
      [Tag.name]: Tag,
      [Button.name]: Button
    },
    props: {
      queryParams: Object
    },
    data() {
      return {
        isLoading: false,
        showSearchList: [],
        searchParams: {
          companyWorkSpaceId: '',
          categoryId: '',
          eduRequire: '',
          jobClassifyId: '',
          jobRankId: '',
          orgId: ''
        },
        cityList: [],
        categoryList: [],
        jobClassifyList: [],
        jobLevel: [],
        orgList: [],
        switchObj: {
          companyWorkSpaceId: true,
          functions: true,
          eduRequire: true,
          jobClassifyId: true,
          jobRankIds: true,
          orgId: true
        }
      }
    },
    computed: {
      ...mapGetters(['educations']),
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      }
    },
    created() {
      // Object.assign(this.searchParams, this.queryParams);
      this.init();
    },
    methods: {
      async init() {
        this.isLoading = true;
        let p1 = await this.queryConfigField();
        let p2 = await this.querySearchCodes();
        if(p1 && p2) {
          this.isLoading = false;
        }
      },
      queryConfigField() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/job/config.json', {params: {
            websiteId: this.queryParams.websiteId
          }}).then(data => {
            this.showSearchList = data || []; 
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },
      loop(arr, newArr) {
        if(!arr) arr = [];
        arr.forEach(item => {
          newArr.push({
            id: item.id,
            name: item.name
          });
          if(item.children && item.children.length) {
            this.loop(item.children, newArr);
          }
        });
        return newArr;
      },
      querySearchCodes() {
        return new Promise((resolve, reject) => {
          this.$http.get('/api/website/code.json', {params: {
            websiteId: this.queryParams.websiteId
          }}).then(data => {
            if(data) {
              this.orgList = this.loop(data.orgList, []) || [];
              this.jobLevel = data.jobRankList || [];
              this.jobClassifyList = data.jobclassifyList || [];
              this.cityList = data.workSpaceList || []; 
              this.categoryList = data.categoryCode || [];
            }
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        });
      },
      onSwitch(field) {
        this.switchObj[field] = !this.switchObj[field];
      },
      onCheck(field, code) {
        if(this.searchParams[field] == code) {
          this.searchParams[field] = '';
        } else {
          this.searchParams[field] = code;
        }
      }
    },
    watch: {
      queryParams: {
        handler(val) {
          this.searchParams = val;
          return val;
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>